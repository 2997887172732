import React from 'react';
import { Link } from 'react-router-dom';

const Footer: React.FC = () => {
    return (
        <footer className="bg-primary p-4">
            <div className="grid grid-cols-3 ml-4 mr-4 text-center text-textColor">
                <p className="text-left">En colaboración con Universidad de Antioquia</p>
                <Link
                    to="/PQSR"
                    className="hover:text-gris2 focus:underline"
                    aria-label="Ir a la página de PQSR"
                >
                    PQSR
                </Link>
                <p className="text-right hover:text-gris2 focus:underline">
                    Versión 5.6.9
                </p>
            </div>
        </footer>
    );
};

export default Footer;
