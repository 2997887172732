import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth, Role } from '../../../Authentication';

const roleConfig: Partial<Record<Role, { title: string; reports: string[] }>> = {
    default: {
        title: "La calidad del aire tiene efectos nocivos en la salud de la población, y en este informe se exploran los impactos de los contaminantes en efectos agudos de salud respiratoria y circulatoria entre 2008 y 2016.",
        reports: [
            "https://app.powerbi.com/view?r=eyJrIjoiMGI5ZjIyOTQtMzk5ZC00OTY1LThiN2YtNGU1ZjFkMTdmNzE5IiwidCI6ImEyYmE0MzQ1LTc3NjQtNGQyMi1iNmExLTdjZjUyOGYzYjNhNSIsImMiOjR9",
            "https://app.powerbi.com/view?r=eyJrIjoiN2EwYzQwOGYtNzI5NS00NjhjLWI5NDUtNjMyNWNiMDU5OGZiIiwidCI6ImEyYmE0MzQ1LTc3NjQtNGQyMi1iNmExLTdjZjUyOGYzYjNhNSIsImMiOjR9"
        ]
    },

    Técnico: {
        title: "",
        reports: [
            "https://app.powerbi.com/view?r=eyJrIjoiMGI5ZjIyOTQtMzk5ZC00OTY1LThiN2YtNGU1ZjFkMTdmNzE5IiwidCI6ImEyYmE0MzQ1LTc3NjQtNGQyMi1iNmExLTdjZjUyOGYzYjNhNSIsImMiOjR9",
            "https://app.powerbi.com/view?r=eyJrIjoiN2EwYzQwOGYtNzI5NS00NjhjLWI5NDUtNjMyNWNiMDU5OGZiIiwidCI6ImEyYmE0MzQ1LTc3NjQtNGQyMi1iNmExLTdjZjUyOGYzYjNhNSIsImMiOjR9",
            'https://mmunozal.shinyapps.io/calculadoraGAMEXTv2025/',
            //'https://sistema.shinyapps.io/calculadoraGAMEXTv2/',--->versión profe
            // 'https://sistema1.shinyapps.io/appAcademico2/',Este es el anterior de morbilidad
            // este es el nuevo de morbilidad con pocas variables 'https://sistema.shinyapps.io/calculadoraGAMv2/'
            "https://sistema.shinyapps.io/appMortalidad/"
        ],
    }
};

const getRoleConfig = (role: Role): { title: string; reports: string[] } => {
    return roleConfig[role] || roleConfig.default!;
};

const AnalisisRiesgos: React.FC = () => {
    const { user } = useAuth();
    const { title, reports } = getRoleConfig(user?.features.rol || 'default');

    return (
        <main className="flex-grow bg-gris1">
            <div className="bg-cover bg-center md:h-48" style={{ backgroundImage: 'url("/fondo.png")' }}>
                <div className="mx-auto p-14 justify-center items-center text-center text-textColor">
                    <h1 className="text-3xl md:text-5xl font-bold">Análisis de Riesgo</h1>
                    <br />
                    <div>
                        <Link to="/eventos/agudos" className="px-4 py-2 hover:bg-primary rounded-md">Volver</Link>
                    </div>
                </div>
            </div>
            <div className="p-4 sm:p-8 text-justify text-base md:text-lg max-w-screen-lg mx-auto">
                <div className="border border-primary rounded-md p-4">
                    <h2 className="font-bold text-lg text-center">¿Afecta la calidad del aire la salud de la población vulnerable de los municipios del Valle de Aburrá?</h2>
                    <p className="mt-2">
                        Los estudios de series de tiempo en salud habitualmente asumen una relación lineal entre los impactos en salud (como consultas médicas y defunciones) y los factores de riesgo. 
                        Esto significa que se espera que los efectos en salud aumenten o disminuyan en proporción a la magnitud de estos factores. Sin embargo, en el caso de la contaminación del aire y otros 
                        factores ambientales, la relación con la salud no es lineal.
                    </p>
                    <p className="mt-2">
                        Desde la década de 1990, se emplean modelos aditivos generalizados (GAM) en estos estudios para abordar esta complejidad. Estos modelos utilizan funciones de suavización tipo <em>“thin plate” </em>  para “volver lineal” la relación y así poder estimar el riesgo que los contaminantes del aire representan para la salud pública. Este enfoque ha sido ampliamente probado y respaldado por 
                        expertos en epidemiología ambiental y por instituciones dedicadas a la salud y el medio ambiente en todo el mundo. Forma parte de las herramientas analíticas esenciales para evaluar planes 
                        de acción y estrategias basadas en evidencia científica.
                    </p>
                </div>
            </div>
            <h2 className="font-bold text-primary text-lg text-center mb-4 mr-4 ml-4">{title}</h2>
            {reports.length > 0 ? (
                reports.map((reportSrc, index) => (
                    <div key={index} className="w-full h-screen mb-20">
                        <iframe
                            allowFullScreen={true}
                            src={reportSrc}
                            title={`Report ${index + 1}`}
                            className="w-full h-full border border-gray-300 rounded-md"
                        ></iframe>
                        <div className="flex justify-center mt-4 space-x-4">
                            <button onClick={() => window.location.reload()} className="bg-primary text-textColor px-4 py-2 rounded-md">Limpiar Consulta</button>
                            <button onClick={() => window.print()} className="bg-primary text-textColor px-4 py-2 rounded-md">Imprimir/Guardar PDF</button>
                        </div>
                    </div>
                ))
            ) : (
                <p className="text-center font-bold text-black mt-10 mb-10">No hay reportes disponibles para mostrar.</p>
            )}
        </main>
    );
};

export default AnalisisRiesgos;
